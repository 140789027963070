import React from 'react'
import './footer.css'

function Footer() {
  return (
    <div className='footer__container'>
<div ><small>Erstellt mit React von Elias Bräm, © 2023</small></div>
    </div>
    
  )
}

export default Footer